import MailTemplateForm from '@/components/MailTemplateForm/MailTemplateForm.vue'
import ErrorMixin from '@/mixins/error.mixin'

export default {
  props: ['id'],
  mixins: [ErrorMixin],
  components: {
    MailTemplateForm
  },
  data() {
    return {
      name: null
    }
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          text: 'Settings',
          to: { name: 'settings' },
          exact: true
        },
        {
          text: 'Mail Templates',
          disabled: false,
          to: { name: 'list-mail-templates' },
          exact: true
        },
        { text: `Edit ${this.name}` }
      ]
    } 
  },
  methods: {
    setName(v) {
      this.name = v
    }
  },
  created() {
    if (_.isNaN(parseInt(this.id)) || parseInt(this.id) > 2147483647) {
      this.renderError(404)
    }
  }
}
